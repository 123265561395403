import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./contact.css";
import ContainerHead from "../../shared/container-head/ContainerHead";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Contact = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: ""
    });

    const [errors, setErrors] = useState({});
    const [statusMessage, setStatusMessage] = useState("");

    const { ref, inView } = useInView({
        triggerOnce: true, // Animates only once when in view
        threshold: 0.1, // Trigger animation when 10% of the component is in view
    });

    const validate = () => {
        const errors = {};
        if (!formData.firstName) errors.firstName = "First Name is required";
        if (!formData.lastName) errors.lastName = "Last Name is required";
        if (!formData.email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = "Email address is invalid";
        }
        if (!formData.phone) errors.phone = "Phone Number is required";
        if (!formData.message) errors.message = "Message is required";
        return errors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
            emailjs.sendForm('service_32unt75', 'template_pxc0cus', e.target, 'ygKN_-2_Sv2AK_toF')
                .then((result) => {
                    setStatusMessage("Message sent successfully!");
                    setFormData({
                        firstName: "",
                        lastName: "",
                        email: "",
                        phone: "",
                        message: ""
                    });
                    setErrors({});
                }, (error) => {
                    // setStatusMessage("Failed to send the message, please try again.");
                });
        } else {
            setErrors(validationErrors);
            // setStatusMessage("Please correct the errors in the form.");
        }
    };

    return (
        <div className="contact-content">
            <div className="center-text">
                <ContainerHead
                    title="CONTACT US"
                    subtitle="Any question or remarks? Just write us a message!"
                    description=''
                />
            </div>
            <motion.div
                ref={ref}
                initial={{ opacity: 0 }}
                animate={inView ? { opacity: 1 } : {}}
                transition={{ duration: 1, delay: 0.5 }}
                className="contact-container">
                <div className="contact-left">
                    <p className="contact-left-title">Contact Information</p>
                    <p className="contact-left-description">Say something to start a live chat!</p>
                    <div className="contact-detail"><i className="phone-icon"></i><p className="contact-info">0426 882 139</p></div>
                    <div className="contact-detail"><i className="message-icon"></i><p className="contact-info">admin@a2zcleangroup.com</p></div>
                    <div className="contact-detail"><i className="location-icon"></i><p className="contact-info">Sydney NSW <br/>Australia</p></div>
                </div>
                <div className="contact-right">
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <div className="contact-form-group">
                            <label className="contact-label">First Name</label>
                            <input
                                type="text"
                                name="firstName"
                                className="contact-input"
                                placeholder="Enter first name"
                                value={formData.firstName}
                                onChange={handleChange}
                            />
                            {errors.firstName && <p className="error">{errors.firstName}</p>}
                        </div>
                        <div className="contact-form-group">
                            <label className="contact-label">Last Name</label>
                            <input
                                type="text"
                                name="lastName"
                                className="contact-input"
                                placeholder="Enter last name"
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                            {errors.lastName && <p className="error">{errors.lastName}</p>}
                        </div>
                        <div className="contact-form-group">
                            <label className="contact-label">Email</label>
                            <input
                                type="email"
                                name="email"
                                className="contact-input"
                                placeholder="Enter email address"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            {errors.email && <p className="error">{errors.email}</p>}
                        </div>
                        <div className="contact-form-group">
                            <label className="contact-label">Phone Number</label>
                            <input
                                type="tel"
                                name="phone"
                                className="contact-input"
                                placeholder="Enter phone number"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                            {errors.phone && <p className="error">{errors.phone}</p>}
                        </div>
                        <div className="contact-form-group-textarea">
                            <label className="contact-label">Message</label>
                            <input
                                name="message"
                                className="contact-textarea"
                                placeholder="Write your message"
                                value={formData.message}
                                onChange={handleChange}
                            ></input>
                            {errors.message && <p className="error">{errors.message}</p>}
                        </div>
                        <button className="contact-button" type="submit">Send Message</button>
                    </form>
                    {statusMessage && <p className="status-message">{statusMessage}</p>}
                </div>
            </motion.div>
        </div>
    );
};

export default Contact;
