import React, { useRef, useState } from "react";
import Header from "../../shared/header/Header";
import Landing from "../../components/landing/Landing";
import About from "../../components/about/About";
import Contact from "../../components/contact/Contact";
import Services from "../../components/services/Services";
import Footer from "../../shared/footer/Footer";
import "./home.css";
import Reviews from "../../components/reviews/Reviews";

const Home = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const contactRef = useRef(null);
  const serviceRefs = {
    "Strata Cleaning": useRef(null),
    "Office Cleaning": useRef(null),
    "Window Cleaning": useRef(null),
    "Commercial Cleaning": useRef(null),
    "Childcare Cleaning": useRef(null),
    "Gym Cleaning": useRef(null),
    "Medical Cleaning": useRef(null),
    "High Pressure Cleaning": useRef(null),
  };

  const scrollToSection = (section, service = null) => {
    const scrollOptions = {
      behavior: "smooth",
      block: "start"
    };

    if (service && serviceRefs[service]) {
      serviceRefs[service].current.scrollIntoView(scrollOptions);
      return;
    }

    switch (section) {
      case "home":
        homeRef.current.scrollIntoView(scrollOptions);
        break;
      case "about":
        aboutRef.current.scrollIntoView(scrollOptions);
        break;
      case "services":
        servicesRef.current.scrollIntoView(scrollOptions);
        break;
      case "contact":
        contactRef.current.scrollIntoView(scrollOptions);
        break;
      default:
        break;
    }
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      <div
        ref={homeRef}
        className={`intro ${imageLoaded ? "visible" : ""}`}
        onLoad={handleImageLoad}
      >
        <Header scrollToSection={scrollToSection} />
        <Landing scrollToSection={scrollToSection} />
      </div>
      {/* <Reviews /> */}
      <div ref={aboutRef} className="about">
        <About />
      </div>
      <div ref={servicesRef} className="services">
        <Services serviceRefs={serviceRefs} />
      </div>
      <div ref={contactRef} className="contact">
        <Contact />
      </div>
      <Footer />
    </>
  );
};

export default Home;
