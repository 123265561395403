import React, { useState } from "react";
import "./services.css";
import { Player } from '@lottiefiles/react-lottie-player';
import ContainerHead from "../../shared/container-head/ContainerHead";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import strataImg from "../../assests/svg/strata.svg";
import officeImg from "../../assests/svg/office.svg";
import windowImg from "../../assests/svg/window.svg";
import commercialImg from "../../assests/svg/commercial.svg";
import childcareImg from "../../assests/svg/childcare.svg";
import gymImg from "../../assests/svg/gym.svg";
import medicalImg from "../../assests/svg/medical.svg";
import highPressureImg from "../../assests/svg/high-pressure.svg";


const cleaningDetails = [
  {
    title: "Strata Cleaning",
    subtitle: "Comprehensive cleaning for common areas in residential buildings and complexes.",
    description1: "Welcome to A2Z Cleaning Group, your premier choice for professional strata cleaning services in Sydney. We specialise in delivering top-notch cleaning solutions tailored to meet the unique needs of strata properties, ensuring pristine and well-maintained spaces for residents and tenants.",
    description2: "At A2Z Cleaning Group, we understand the importance of maintaining the cleanliness and hygiene of strata complexes. Our team of highly trained and experienced cleaners is equipped with the latest tools and techniques to deliver exceptional results, leaving your strata property looking immaculate.",
    description3: "Our comprehensive range of strata cleaning services includes:",
    subCleanings: [
      {
        subtypetitle: "Common Area Cleaning ",
        subtypedescription: 'From lobbies and hallways to stairwells and elevators, we ensure that all common areas are thoroughly cleaned and sanitized to create a welcoming environment for residents and visitors.'
      },
      {
        subtypetitle: "Bin Management ",
        subtypedescription: 'We manage waste and recycling bins efficiently, ensuring proper disposal and maintaining cleanliness in designated areas.'
      },
      {
        subtypetitle: "Grounds Maintenance ",
        subtypedescription: 'Our team takes care of outdoor spaces, including gardens, lawns, and parking areas, to enhance the overall appeal of your strata property.'
      },
      {
        subtypetitle: "High-Pressure Cleaning ",
        subtypedescription: ' We use high-pressure cleaning techniques to remove dirt, grime, and stains from exterior surfaces such as driveways, pathways, and building facades, restoring them to their original condition.'
      },
      {
        subtypetitle: "Window Cleaning ",
        subtypedescription: 'Our professional window cleaning service ensures streak-free, sparkling windows that maximize natural light and enhance the aesthetic appeal of your strata building.'
      },
      {
        subtypetitle: "Strata Maintenance ",
        subtypedescription: 'We offer additional maintenance services, including light bulb replacement, minor repairs, and reporting of any issues discovered during our cleaning visits.'
      },
    ],
    description4: "At A2Z Cleaning Group, we prioritise customer satisfaction and strive to exceed your expectations with every service we provide. Our dedicated team works diligently to deliver superior results on time and within budget, allowing you to focus on managing your strata property with peace of mind. Experience the A2Z Cleaning Group difference in strata cleaning services and transform your property into a clean, inviting, and well-maintained space. Contact us today to schedule a consultation and discover how we can enhance the cleanliness and hygiene of your strata property in Sydney.",
    coverImg: strataImg,
    subImg: 'https://a2zcleangimages.s3.amazonaws.com/service/strata-sub.jpg'
  },
  {
    title: "Office Cleaning",
    subtitle: "Regular cleaning and maintenance services for office environments.",
    description1: "Experience worry-free office cleaning with A2Z Cleaning Group. Our expert services ensure your business maintains a professional atmosphere day after day, allowing you to concentrate on your core operations. Whether you need daily, weekly, or monthly cleaning, we tailor our programs to suit your business’s specific needs.",
    description2: "Presenting a clean environment is crucial for impressing clients, boosting employee morale, and enhancing productivity. Our comprehensive office cleaning services include:",
    description3: "",
    subCleanings: [
      {
        subtypetitle: "Desk and Table Sanitisation",
      },
      {
        subtypetitle: "Restroom Hygiene Management",
      },
      {
        subtypetitle: "Floor Maintenance",
      },
      {
        subtypetitle: "Carpet Care and vacuuming",
      },
      {
        subtypetitle: "Eco-Friendly Waste Management",
      },
      {
        subtypetitle: "Waste Bin Maintenance",
      },
      {
        subtypetitle: "Odour Neutralisation"
      },
      {
        subtypetitle: "Glass and Window Cleansing"
      },
      {
        subtypetitle: "Mould Remediation"
      },
      {
        subtypetitle: "Surface Dusting"
      },
      {
        subtypetitle: "Keyboard and Screen Sanitisation"
      },
      {
        subtypetitle: "Rapid Response Cleaning"
      },
      {
        subtypetitle: "Telephone and Headset Hygiene"
      },
      {
        subtypetitle: "Entrance Area Maintenance"
      },
      {
        subtypetitle: "Wall and Surface Cleansing"
      },
      {
        subtypetitle: "Restroom Supplies Replenishment"
      },
    ],
    description4: "Upon completion of our services, your premises will be secured, with all lights turned off, windows and doors closed and locked, and alarms set. Trust A2Z Cleaning Group for a clean and professional workspace that leaves a lasting impression on clients and employees alike",
    coverImg: officeImg,
    subImg: "https://a2zcleangimages.s3.amazonaws.com/service/office-sub.jpg"
  },
  {
    title: "Window Cleaning",
    subtitle: "Specialise cleaning of interior and exterior windows for buildings.",
    description1: "Discover how A2Z Cleaning Group can enhance the appearance and clarity of your windows with customised professional cleaning services designed to meet your unique needs. Providing peace of mind for property managers and building owners alike, our team at A2Z is committed to delivering superior window cleaning services, ensuring thorough and efficient cleaning every time. Leveraging state-of-the-art equipment and industry-leading cleaning techniques, we effortlessly handle even the most challenging window cleaning tasks.",
    description2: "Our dedication to excellence includes performing regular inspections to ensure every window under our care receives the meticulous attention it deserves.",
    description3: "At A2Z Cleaning Group, our mission is to keep everything running seamlessly and address any issues that may arise promptly. Trust us to maintain your windows to the highest standards, allowing you to focus on what truly matters.",
    coverImg: windowImg,
    subImg: "https://a2zcleangimages.s3.amazonaws.com/service/window-sub.jpg",
  },
  {
    title: "Commercial Cleaning",
    subtitle: "Professional cleaning for various commercial spaces like retail stores and restaurants.",
    description1: "Experience the superior solution for maintaining a pristine workplace with our comprehensive commercial cleaning services designed for businesses of all sizes in Sydney. Opting for a professional cleaning company can transform the efficiency of your business, allowing your employees to concentrate on essential tasks rather than worrying about tidying up.",
    description2: "With our reliable services, you can entrust the tedious cleaning duties to us, ensuring that your business enjoys the advantages of a clean environment without the burden of end-of-day cleanup. We guarantee outstanding outcomes that not only positively represent your business but also elevate employee satisfaction and impress your clients.",
    description3: "A key benefit of partnering with us is the flexibility and personalisation we offer in our cleaning schedules. Enjoy the convenience of a bespoke cleaning plan that seamlessly integrates with your business operations, minimising disruption while maintaining peak cleanliness.",
    description4: "Unleash the full potential of your business with our commercial cleaning services. Allow us to handle the grunt work while you concentrate on propelling your business forward. Reach out to us today to discover how we can assist you in achieving a tidy, professional, and inviting workspace that attracts customers and drives success",
    coverImg: commercialImg,
    subImg: "https://a2zcleangimages.s3.amazonaws.com/service/commercial-sub.jpg",
  },
  {
    title: "Childcare Cleaning",
    subtitle: "Safe and hygienic cleaning services tailored for childcare centres and facilities.",
    description1: "Looking for professional childcare centre cleaning services in Sydney and NSW? Concerned about the safety and well-being of the children in your daycare? If your current cleaning provider isn't meeting your standards, it may be time to consider expert childcare cleaning services.",
    description2: "At A2Z Cleaning Group, we understand the importance of maintaining a clean and safe environment for children. That's why we've introduced our exceptional childcare cleaning services, designed to provide you with a safer, healthier, and more hygienic centre for both you and the kids.",
    description3: "Let's face it: parents prioritise cleanliness and safety when choosing a childcare provider. That's why childcare centre cleaning is crucial. But you don't have to worry anymore. We're here to help. Our comprehensive childcare cleaning services are tailored to keep your centre clean, fresh, and beautiful while ensuring the safety and well-being of the children.",
    description4: "Childcare cleaning is our specialty, and we take great pride in it. Whether you need a quick clean or regular daily/weekly cleaning services, we've got you covered. Our services include everything from floor cleaning and carpet steam cleaning to window cleaning, toy and equipment cleaning, outdoor glass cleaning, and touchpoint disinfection. Contact A2Z Cleaning Group today to schedule your childcare facility cleaning. Reach us at 1300 141 946. Let us take care of the cleaning so you can focus on providing a safe and nurturing environment for the children in your care.",
    coverImg: childcareImg,
    subImg: "https://qualitycleanvictoria.com/wp-content/uploads/2023/03/preschool.jpg",
  },
  {
    title: "Gym Cleaning",
    subtitle: "Thorough cleaning and sanitization of gym equipment and facilities to ensure a healthy environment.",
    description1: "Looking for top-notch gym cleaning services in Sydney? Look no further than A2Z Cleaning Group. We pride ourselves on our commitment to excellence and our team of highly trained, experienced, and reliable gym cleaners. Fully licensed and insured, we utilise advanced technologies and eco-friendly materials to ensure a thorough cleaning of your fitness center or gymnasium.",
    description2: "Our specialised chemicals are designed to create a safe environment, and our techniques leave the entire gym, including machines, toilets, and shower areas, dry and odourless within two hours. Offering a wide range of services such as mopping, steam cleaning, deep cleaning, polishing, and vacuuming, we guarantee the best results for your gym cleaning needs.",
    description3: "At A2Z Cleaning Group, we understand the challenges of maintaining a clean and hygienic gym environment. That's why we offer comprehensive gym cleaning assistance, including machine polishing, window washing, air refreshing, carpet cleaning, toilet sanitation, soap residue removal from shower areas, and complete gym sanitisation.",
    description4: "Our goal is to provide you with a fresh, healthy, and immaculate gym environment that promotes the well-being of your members. Contact A2Z Cleaning Group today to experience the difference in gym cleaning services. Don't forget to visit our Facebook page for updates.",
    coverImg: gymImg,
    subImg: "https://a2zcleangimages.s3.amazonaws.com/service/gym-sub.jpg",
  },
  {
    title: "Medical Cleaning",
    subtitle: "Strict and hygienic cleaning protocols for medical and healthcare facilities.",
    description1: "A2Z Cleaning Group is your trusted partner for comprehensive medical and hospital cleaning services in Sydney. With a focus on safety and hygiene, we Specialise in delivering exceptional cleaning solutions tailored to meet the unique needs of medical facilities.",
    description2: "Our team of talented and trained medical cleaners has access to world-class and eco-friendly cleaning resources, supplies, and equipment, ensuring consistently great cleaning results for our clients across industries. We offer both routine cleaning and one-time medical office cleaning services, along with customised solutions to meet the specific cleaning requirements of different medical centres and facilities in Australia. Our cleaners are available for medical cleaning services on weekdays and weekends, providing flexibility and convenience to our clients.",
    description3: "In addition to medical cleaning, we also offer a range of all-inclusive property cleaning services, including gym cleaning, childcare centre cleaning, office cleaning, departmental store cleaning, factory/warehouse cleaning, and after builders cleaning services. At A2Z Cleaning Group, we use expertise, specialist knowledge, and comprehensive training to clean healthcare centres and sites with the utmost care. Our skilled experts utilise high-end cleaning equipment and compliance procedures to achieve superior hygiene standards and prevent cross-contamination, ensuring the safety and well-being of patients and staff.",
    description4: "Contact us for highly efficient and quality-driven healthcare cleaning services that are affordable and tailored to your specific needs. Experience the difference with A2Z Cleaning Group - your partner for impeccable medical cleaning solutions in Sydney.",
    coverImg: medicalImg,
    subImg: "https://a2zcleangimages.s3.amazonaws.com/service/medical-sub.jpg",
  },
  {
    title: "High Pressure Cleaning",
    subtitle: "Blast away dirt and grime with our precision high-pressure cleaning",
    description1: "Elevate the cleanliness and appeal of your property with A2Z Cleaning Group's high-pressure cleaning services. Our expert team utilises advanced techniques and state-of-the-art equipment to effectively remove dirt, grime, and stains from exterior surfaces, including driveways, pathways, and building facades.",
    description2: "With our high-pressure cleaning services, you can rejuvenate the appearance of your property and enhance its curb appeal. Whether you're a homeowner, business owner, or property manager, our services are tailored to meet your specific needs and deliver exceptional results.",
    description3: "Experience the difference with A2Z Cleaning Group and transform your property into a pristine and inviting space. Contact us today to schedule your high-pressure cleaning service and discover the unmatched quality and professionalism we bring to every project.",
    coverImg: highPressureImg,
    subImg: "https://a2zcleangimages.s3.amazonaws.com/service/pressure-sub.jpg"
  }
];

const ServiceBox = React.forwardRef(({ service, toggleDetails }, ref) => {
  const { ref: inViewRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div
      ref={inViewRef}
      className="service-box">
      <img className="cleaning-img" src={service.coverImg} alt="cleaning" />
      <div className="cleaning-details">
        <motion.div
          // ref={ref}
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
          className="cleaning-title"
        >
          {service.title}
        </motion.div>
        <motion.div
          // ref={ref}
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : {}}
          transition={{ duration: 1 }}
          className="cleaning-subtitle"
        >
          {service.subtitle}
        </motion.div>
        <div className="see-more-icon" onClick={toggleDetails}>
          <Player
            src="https://lottie.host/b8708e6b-c34b-48e6-bdcb-4cafefe5e2d6/oWxRsS4Ftj.json"
            loop
            autoplay
          />
        </div>
      </div>
    </div>
  );
});


const HiddenDetails = ({ service, isVisible }) => (
  <div className={`hidden-details ${isVisible ? "visible" : ""}`}>
    <div className="more-details">
      <img className="sub-cleaning-img" src={service.subImg} alt="subCleaning" />
      <div className="cleaning-para">
        <p className="cleaning-description">{service.description1}</p>
        <p className="cleaning-description">{service.description2}</p>
        <p className="cleaning-description">{service.description3}</p>
        <div className="sub-cleaning-description">
          {service.subCleanings && service.subCleanings.map((subCleaning, index) => (
            <p key={index} className="sub-cleaning-subtitle">{subCleaning.subtypetitle}</p>
          ))}
        </div>
        <p className="cleaning-description">{service.description4}</p>
      </div>
    </div>
  </div>
);

const Services = ({ serviceRefs }) => {
  const [visibleIndex, setVisibleIndex] = useState(null);

  const toggleDetails = (index) => {
    setVisibleIndex(visibleIndex === index ? null : index);
  };

  return (
    <div className="service-content">
      <div className="center-text">
        <ContainerHead
          title="OUR SERVICES"
          subtitle="GET TO KNOW WHAT WE CAN DO"
          description=""
        />
      </div>
      <h1 className="container-title" style={{ textAlign: "center" }}></h1>
      <p className="container-subtitle" style={{ textAlign: "center" }}></p>
      <div className="service-container">
        {cleaningDetails.map((service, index) => (
          <React.Fragment key={index}>
            <div ref={serviceRefs[service.title]}></div>
            <ServiceBox
              ref={serviceRefs[service.title]}
              service={service}
              toggleDetails={() => toggleDetails(index)}
            />
            <HiddenDetails
              service={service}
              isVisible={visibleIndex === index}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Services;
