import React from "react";
import "./footer.css";

const Footer = () => {
    return (
        <footer>
            <div className="footer-container">
                <div className="col1">
                    <p className="footer-description">Experience the A2Z Cleaning Group difference—where excellence in cleaning meets exceptional customer service. Trust us to keep your spaces immaculate, safe, and inviting.</p>
                </div>
                <div className="col2">
                    <h4 className="footer-title">Contact</h4>
                    <p className="footer-list">Phone: 0426 882 139</p>
                    <p className="footer-list">Email: admin@a2zcleangroup.com</p>
                </div>
                <div className="col2">
                    <h4 className="footer-title">Services</h4>
                    <p className="footer-list">Strata Cleaning</p>
                    <p className="footer-list">Office Cleaning</p>
                    <p className="footer-list">Window Cleaning</p>
                    <p className="footer-list">Commercial Cleaning</p>                   
                    <p className="footer-list">Childcare Cleaning</p>
                    <p className="footer-list">Gym Cleaning</p>
                    <p className="footer-list">Medical Cleaning</p>
                    <p className="footer-list">High-Pressure Cleaning</p>
                </div>
                <div className="col2">
                    <h4 className="footer-title">Customers</h4>
                    <p className="footer-list">Hospitals</p>
                    <p className="footer-list">Gyms</p>
                    <p className="footer-list">Offices</p>
                    <p className="footer-list">Houses</p>
                </div>
                <div className="col2">
                    <h4 className="footer-title">About</h4>
                    <p className="footer-list">Help</p>
                    <p className="footer-list">Privacy Popcy</p>
                    <p className="footer-list">Terms & Conditions</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
