import React from "react";
import "./about.css";
import ContainerHead from "../../shared/container-head/ContainerHead";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import aboutImg from "../../assests/svg/about-img.svg";

const About = () => {
    const { ref, inView } = useInView({
        triggerOnce: true, // Animates only once when in view
        threshold: 0.1, // Trigger animation when 10% of the component is in view
    });
    return (
        <div className="about-content">
            <div className="about-left">
                <ContainerHead
                    title="ABOUT US"
                    subtitle="QUICKLY AND EASY TO CLEAN YOUR OFFICE AND HOUSE"
                    description="At A2Z Clean Group, we meticulously select our team members, ensuring they possess the necessary qualifications to meet our stringent standards. Adorned in professional uniforms, our staff is thoroughly trained and equipped to handle any task, regardless of size, on-site. Environmental consciousness is ingrained in our operations. We prioritize sustainability by utilizing only the latest equipment and the safest cleaning products available, contributing to a cleaner, healthier environment. As an award-winning company with over a decade of experience, A2Z Clean Group is your trusted partner for all your facility service needs."
                />
            </div>
            <motion.div
                ref={ref}
                initial={{ opacity: 0, y: 20 }}
                animate={inView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1 }} className="about-right">
                <img className="about-img" src={aboutImg} alt="cleaning" />
            </motion.div>
        </div>
    );
};

export default About;
