import React from "react";
import './landing.css';

const Landing = ({ scrollToSection }) => {
    return (
        <div className="landing-container">
            <div className="landing-content">
                <h1 className="landing-title">A New Solution For Your Cleaning Works</h1>
                <div className="landing-line"></div>
                <p className="landing-subtitle">With a decade of experience in the cleaning industry, we've garnered a reputation for excellence and sustained growth. Our seasoned team is dedicated to delivering unparalleled quality and reliability, consistently exceeding our customers' expectations.</p>
                <button className="landing-button" onClick={() => scrollToSection("contact")}>BOOK NOW</button>
            </div>
        </div>
    );
};

export default Landing;