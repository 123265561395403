import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./container-head.css";

const ContainerHead = ({ title, subtitle, description }) => {
    const { ref, inView } = useInView({
        triggerOnce: true, // Animates only once when in view
        threshold: 0.1, // Trigger animation when 10% of the component is in view
    });

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1 }}
            className="container-head fade-in"
        >
            <h1 className="container-title">{title}</h1>
            <h2 className="container-subtitle">{subtitle}</h2>
            <p className="container-description">{description}</p>
        </motion.div>
    );
};

export default ContainerHead;
