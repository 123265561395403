import React, { useState, useEffect, useRef } from "react";
import "./header.css";
import menuIcon from "../../assests/images/menu.png";
import PropTypes from "prop-types";
import logoImg from "../../assests/svg/logo.svg";

const Header = ({ scrollToSection }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const navHeader = document.querySelector(".nav-header");
      if (window.scrollY > 50) {
        navHeader.classList.add("scrolled");
      } else {
        navHeader.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="nav-header">
        <div className="left">
          <img src={logoImg} alt="logo" className="logo" />
          <div className="menu">
            <p className="menu-option" onClick={() => scrollToSection("home")}>
              Home
            </p>
            <p className="menu-option" onClick={() => scrollToSection("about")}>
              About
            </p>
            <p className="menu-option">
              <div className="dropdown">
                <p className="dropbtn">Services</p>
                <div className="dropdown-content">
                  <a
                    onClick={() =>
                      scrollToSection("services", "Strata Cleaning")
                    }
                  >
                    Strata Cleaning
                  </a>
                  <a
                    onClick={() =>
                      scrollToSection("services", "Office Cleaning")
                    }
                  >
                    Office Cleaning
                  </a>
                  <a
                    onClick={() =>
                      scrollToSection("services", "Window Cleaning")
                    }
                  >
                    Window Cleaning
                  </a>
                  <a
                    onClick={() =>
                      scrollToSection("services", "Commercial Cleaning")
                    }
                  >
                    Commercial Cleaning
                  </a>
                  <a
                    onClick={() =>
                      scrollToSection("services", "Childcare Cleaning")
                    }
                  >
                    Childcare Cleaning
                  </a>
                  <a
                    onClick={() => scrollToSection("services", "Gym Cleaning")}
                  >
                    Gym Cleaning
                  </a>
                  <a
                    onClick={() =>
                      scrollToSection("services", "Medical Cleaning")
                    }
                  >
                    Medical Cleaning
                  </a>
                  <a
                    onClick={() =>
                      scrollToSection("services", "High Pressure Cleaning")
                    }
                  >
                    High Pressure Cleaning
                  </a>
                </div>
              </div>
            </p>
            <p
              className="menu-option"
              onClick={() => scrollToSection("contact")}
            >
              Contact
            </p>
          </div>
        </div>
        <div className="right">
          <button className="nav-button call-button">
            <i className="contact-icon"></i>
            <p>0426 882 139</p>
          </button>
          <button className="nav-button quote-button"  onClick={() => scrollToSection("contact")}>
            <i className="quote-icon"></i>Get a Quote
          </button>
          <img
            className="menu-icon"
            src={menuIcon}
            alt="menu-icon"
            onClick={toggleModal}
          />
        </div>
      </div>
      {isModalOpen && (
        <div className={`modal ${isModalOpen ? "show" : ""}`}>
          <div className="modal-content" ref={modalRef}>
            <p
              className="menu-option"
              onClick={() => {
                scrollToSection("home");
                toggleModal();
              }}
            >
              Home
            </p>
            <p
              className="menu-option"
              onClick={() => {
                scrollToSection("about");
                toggleModal();
              }}
            >
              About
            </p>
            <p className="menu-option">
              <div className="dropdown">
                <p className="dropbtn">Services</p>
                <div className="dropdown-content">
                  <a
                    onClick={() =>
                      scrollToSection("services", "Strata Cleaning")
                    }
                  >
                    Strata Cleaning
                  </a>
                  <a
                    onClick={() =>
                      scrollToSection("services", "Office Cleaning")
                    }
                  >
                    Office Cleaning
                  </a>
                  <a
                    onClick={() =>
                      scrollToSection("services", "Window Cleaning")
                    }
                  >
                    Window Cleaning
                  </a>
                  <a
                    onClick={() =>
                      scrollToSection("services", "Commercial Cleaning")
                    }
                  >
                    Commercial Cleaning
                  </a>
                  <a
                    onClick={() =>
                      scrollToSection("services", "Childcare Cleaning")
                    }
                  >
                    Childcare Cleaning
                  </a>
                  <a
                    onClick={() => scrollToSection("services", "Gym Cleaning")}
                  >
                    Gym Cleaning
                  </a>
                  <a
                    onClick={() =>
                      scrollToSection("services", "Medical Cleaning")
                    }
                  >
                    Medical Cleaning
                  </a>
                  <a
                    onClick={() =>
                      scrollToSection("services", "High Pressure Cleaning")
                    }
                  >
                    High Pressure Cleaning
                  </a>
                </div>
              </div>
            </p>
            <p
              className="menu-option"
              onClick={() => {
                scrollToSection("contact");
                toggleModal();
              }}
            >
              Contact
            </p>
          </div>
        </div>
      )}
    </>
  );
};

Header.propTypes = {
  scrollToSection: PropTypes.func.isRequired,
};

export default Header;
